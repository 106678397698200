import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../components/UI';
import { useFormatLocations } from '../../../../hooks/useFormatLocations';
import { selectAccessFilterOptions, toggleAccessFilter } from '../../../../redux/slices/accessSlice';
import { getAccessFilteredEventsThunk } from '../../../../redux/thunks/accessThunk';
import s from './AccessFilterModal.module.scss';
import DateSection from './components/DateSection/DateSection';
import LocationSection from './components/LocationSection/LocationSection';

const AccessFilterModal = () => {
  const dispatch = useDispatch();
  const { locations } = useSelector(selectAccessFilterOptions);
  const { isFilteredEventsLoading } = useSelector(state => state.access);

  const { allRegions, allCountries, allCities } = useFormatLocations(locations, true);

  const isFilterDisable = !allCountries.checkCount || (allCities.visibleCount && !allCities.checkCount);

  const onFilterClick = () => {
    dispatch(getAccessFilteredEventsThunk());
  };

  return (
    <div className={s.background}>
      <div className={s.modal}>
        <div className={s.title}>Event Filters</div>
        <div className={s.content}>
          <div className={s.rightSection}>
            <LocationSection
              allRegions={allRegions}
              allCountries={allCountries}
              allCities={allCities}
          />
            <DateSection />
          </div>
        </div>
        <div className={s.actions}>
          <Button
            variant='light'
            onClick={() => dispatch(toggleAccessFilter(false))}
          >
            Cancel
          </Button>
          <Button
            loading={isFilteredEventsLoading}
            disabled={isFilterDisable}
            className={s.filterButton}
            onClick={onFilterClick}
        >
            Filter
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccessFilterModal;
