import Report from '../../pages/CaseEventAnalysis/Report/Report';
import PastEvents from '../../pages/Events/PastEvents';
import DraftEvents from '../../pages/Events/DraftEvents';
import CustomerSatisfaction from '../../pages/OverallAnalysis/CustomersSatisfaction/CustomerSatisfaction';
import GeneralAnalysis from '../../pages/OverallAnalysis/GeneralAnalysis/GeneralAnalysis';
import PastEventAnalysis from '../../pages/OverallAnalysis/PastEventAnalysis/PastEventAnalysis';
import Approved from '../../pages/PatientCases/Approved';
import Draft from '../../pages/PatientCases/Draft';
import Settings from '../../pages/Settings/Settings';
import { USER_ROLES_IDS } from '../../constants/ids';
import ApprovedEvents from '../../pages/Events/ApprovedEvents';
// import EC_AdminAccess from '../../pages/EC_AdminAccess/EC_AdminAccess';
import Access from '../../pages/Access/Access';
import ModeratorEvents from '../../pages/Events/ModeratorEvents';

const {
  SUPER_USER, EC_ADMIN, EVENT_MODERATOR, ANALYSIS_ADMIN,
} = USER_ROLES_IDS;
const allRoles = Object.values(USER_ROLES_IDS);

export const ALL_ROUTES = [
  {
    multi: true,
    name: 'Events',
    path: '/events',
    roles: [ SUPER_USER, EC_ADMIN, EVENT_MODERATOR ],
    childs: [
      {
        name: 'Approved',
        path: '/approved',
        Component: ApprovedEvents,
        roles: [ SUPER_USER, EC_ADMIN ],
      },
      {
        name: 'Draft',
        path: '/draft',
        Component: DraftEvents,
        roles: [ SUPER_USER, EC_ADMIN ],
      },
      {
        name: 'Past',
        path: '/past',
        Component: PastEvents,
        roles: [ SUPER_USER, EC_ADMIN ],
      },
      {
        name: 'Available',
        path: '/avilable',
        Component: ModeratorEvents,
        roles: [ EVENT_MODERATOR ],
      },
    ],
  },
  {
    multi: true,
    name: 'Patient Cases',
    path: '/patientCases',
    roles: [ SUPER_USER, EC_ADMIN ],
    childs: [
      {
        name: 'Approved',
        path: '/approved',
        Component: Approved,
        roles: [ SUPER_USER, EC_ADMIN ],
      },
      {
        name: 'Draft',
        path: '/draft',
        Component: Draft,
        roles: [ SUPER_USER, EC_ADMIN ],
      },
    ],
  },
  {
    multi: true,
    name: 'Overall Analysis',
    path: '/overallAnalysis',
    roles: allRoles,
    childs: [
      {
        name: 'General Participation',
        path: '/generalParticipation',
        Component: GeneralAnalysis,
        roles: allRoles,
      },
      {
        name: 'Past Event Analysis',
        path: '/past',
        Component: PastEventAnalysis,
        roles: [ SUPER_USER, EC_ADMIN, ANALYSIS_ADMIN ],
      },
      {
        name: 'Customer Satisfaction',
        path: '/customerSatisfaction',
        Component: CustomerSatisfaction,
        roles: allRoles,
      },
    ],
  },
  {
    multi: true,
    name: 'Case/Event Analysis',
    path: '/caseEventAnalysis',
    roles: allRoles,
    childs: [
      // {
      //   name: 'Questions & Answers',
      //   path: '/questionsAnswers',
      //   Component: QuestionsAnswers,
      //   roles: allRoles,
      // },
      {
        name: 'Report',
        path: '/report',
        Component: Report,
        roles: allRoles,
      },
    ],
  },
  {
    name: 'Event Moderator Access',
    path: '/eventModeratorAccess',
    roles: [ SUPER_USER ],
    Component: props => <Access isModerator {...props} />,
  },
  // {
  //   name: 'Event and Case Administrator Access',
  //   path: '/ecAdminAccess',
  //   roles: [ SUPER_USER ],
  //   Component: Access,
  // },
  {
    name: 'Settings',
    path: '/settings',
    roles: [ SUPER_USER ],
    Component: Settings,
  },
];

export const getValidRoutes = (role) => {
  if (!role) return [];
  const filteredChildsRoutes = ALL_ROUTES.map((route) => {
    if (!route.multi) return route;
    const copyRoute = { ...route };
    copyRoute.childs = copyRoute.childs.filter(child => child.roles.includes(role));
    return copyRoute;
  });
  return filteredChildsRoutes.filter(route => route.roles.includes(role));
};
