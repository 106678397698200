import QRCode from 'qrcode.react';
import { saveAs } from 'file-saver';
import { Button } from '../UI';
import s from './QRPopup.module.scss';

const QRPopup = ({ onClose, data }) => {
  const saveImage = () => {
    const qrImage = document.querySelector('#qr-code');
    qrImage.toBlob((blob) => {
      saveAs(blob, `${data.name}.png`);
    });
  };

  return (
    <div className={s.photoPopup}>
      <div
        className={s.photoPopupClose} />
      <div className={s.photoPopup__inner}>
        <div className={s.photoPopup__pic}>
          <div className={s.qrWrapper}>
            <div>
              <QRCode
                id='qr-code'
                value={process.env.REACT_APP_FRONT_URL + data.uuid}
                size={260}
                />
            </div>
            <div className={s.saveImageButtonWrapper}>
              <Button onClick={saveImage} className={s.saveImageButton}>Save Image</Button>
            </div>
          </div>
          <div
            className={s.photoPopup__close}
            onClick={e => onClose()}>
            <svg className={s.photoPopup__closeSvg}>
              <use xlinkHref="#close" />
            </svg>
          </div>
        </div>
      </div>
    </div>

  );
};

export default QRPopup;
