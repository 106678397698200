import { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {
  Button, Datepicker, Input, Radio, Select, Timepicker,
} from '../../../../components/UI';
import Modal from '../../../../components/UI/Modal/Modal';
import { EVENT_TYPE_NAMES } from '../../../../constants/names';
import { EVENT_TYPE_IDS } from '../../../../constants/ids';
import s from './FutureEventModal.module.scss';
import Row from '../../../../components/UI/Row/Row';
import { createModifyEventThunk, deleteEventThunk } from '../../../../redux/thunks/eventsDataThunk';
import { setConfirm } from '../../../../redux/slices/confirmSlice';
import AddPatientModal from './components/AddPatientModal/AddPatientModal';
import TimeZoneDropDown from './components/TimeZoneDropDown/TimeZoneDropDown';
import EventExpectedParticipants from './components/EventExpectedParticipants/EventExpectedParticipants';
import { modifySelectOptions } from '../../../../helpers/utils';
import { EVENT_CONFIGS_KEYS } from '../../../../configs/events.configs';
import { PATIENT_BASE_INFO_KEYS } from '../../../../configs/patients.configs';
import { getEventPatientsThunk } from '../../../../redux/thunks/patientsDataThunk';

const {
  GENDER, AGE, FIRST_NAME, LAST_NAME, VISIT,
} = PATIENT_BASE_INFO_KEYS;

const eventTypes = Object.values(EVENT_TYPE_IDS);

const {
  AREA, COUNTRY, REGION, LANGUAGE,
} = EVENT_CONFIGS_KEYS;

const initialState = {
  eventType: EVENT_TYPE_IDS.VIRTUAL,
  name: '',
  language: '',
  city: '',
  country: '',
  region: '',
  startDate: new Date() - 8.64e+7,
  startTime: '10:00',
  endDate: null,
  therapeuticArea: '',
  expectedParticipants: '',
  cases: [],
  timeZone: '',
};

const FutureEventModal = ({
  closeModal, type, innerFormData, restartFetch, isView,
}) => {
  const dispatch = useDispatch();
  const { isCreateLoading, isDeleteLoading } = useSelector(state => state.eventsData);
  const eventConfigs = useSelector(state => state.userInfo.eventConfigs.configs);
  const [ formData, setFormData ] = useState(innerFormData || initialState);
  const [ isPatientModalActive, setIsPatientModalActive ] = useState(false);

  const regionOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[REGION])
  ), [ eventConfigs[REGION] ]);
  const [ regionSelect, setRegionSelect ] = useState(
    regionOptions.find(item => item.value === formData.region) || null,
  );

  const countryOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[COUNTRY])
  ), [ eventConfigs[COUNTRY] ]);
  const [ countrySelect, setcountrySelect ] = useState(
    countryOptions.find(item => item.value === formData.country) || null,
  );

  const therapeuticOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[AREA])
  ), [ eventConfigs[AREA] ]);
  const [ therapeuticSelect, setTherapeuticSelect ] = useState(
    therapeuticOptions.find(item => item.value === formData.therapeuticArea) || null,
  );

  const languageOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[LANGUAGE])
  ), [ eventConfigs[LANGUAGE] ]);
  const [ languageSelect, setLanguageSelect ] = useState(
    languageOptions.find(item => item.value === formData.language) || null,
  );

  const isModify = type === 'Modify';
  const startDate = formData.startDate ? new Date(formData.startDate) : null;
  const startTime = formData.startTime;
  const endDate = formData.endDate ? new Date(formData.endDate) : null;

  const formHandler = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const removePatientCase = (id) => {
    setFormData(prev => ({ ...prev, cases: prev.cases.filter(item => item.id !== id) }));
  };

  const deleteEvent = () => {
    dispatch(deleteEventThunk({
      id: formData.id, closeModal, restartFetch,
    }));
  };

  const onDeleteClick = () => {
    dispatch(setConfirm(deleteEvent));
  };

  const onTypeClick = (value) => {
    formHandler('eventType', value);
  };

  const isOkDisable = !formData.name || !formData.expectedParticipants || !formData.cases?.length
  || !therapeuticSelect || !countrySelect || !regionSelect || !languageSelect || isDeleteLoading
  || !formData.startTime || !formData.timeZone;

  const isAddPatientDisabled = !languageSelect || !therapeuticSelect;

  const onFormSubmit = () => {
    if (isOkDisable) return;
    const formatedData = {
      ...formData,
      startDate: dayjs(formData.startDate).format('YYYY-MM-DD'),
      endDate: formData.endDate ? dayjs(formData.endDate).format('YYYY-MM-DD') : null,
      startTime: formData.startTime,
      cases: formData.cases.map(el => el.id),
      country: countrySelect?.value,
      region: regionSelect?.value,
      therapeuticArea: therapeuticSelect?.value,
      language: languageSelect?.value,
      timeZone: formData.timeZone,
    };
    dispatch(createModifyEventThunk({
      isModify, data: formatedData, closeModal, restartFetch,
    }));
  };

  const onPatientModalOk = (checkedCases) => {
    formHandler('cases', checkedCases);
    setIsPatientModalActive(false);
  };

  const getPatientsByLanguage = page => dispatch(getEventPatientsThunk({
    language: languageSelect?.value, area: therapeuticSelect?.value, page,
  }));

  return (
    <Modal
      title={`${isView ? 'View' : type} Event`}
      actions={
        <div className={s.actions}>
          <div>

            {isModify
            && <Button
              loading={isDeleteLoading}
              onClick={onDeleteClick}
              disabled={isCreateLoading}
              variant='danger'>Delete</Button>}
          </div>
          <div>

            <Button onClick={closeModal} className={s.cancelButton} variant='light'>Cancel</Button>
            <Button
              disabled={isOkDisable}
              loading={isCreateLoading}
              onClick={onFormSubmit}>OK</Button>
          </div>
        </div>
    }>
      <div className={classNames(s.content, { [s.view]: isView })}>
        <div className={s.row}>
          <div>Name of Event<span className={s.required}>*</span>:</div>
          <div>
            <Input
              name='name'
              onChange={({ target }) => !isView && formHandler(target.name, target.value)}
              value={formData.name} />
          </div>
        </div>
        <div className={s.row}>
          <div>Therapeutic Area<span className={s.required}>*</span>:</div>
          <div>
            <Select
              isDisabled={isView}
              isSearchable
              placeholder='Select Therapeutic Area...'
              value={therapeuticSelect}
              onChange={value => setTherapeuticSelect(value)}
              options={therapeuticOptions} />
          </div>
        </div>        <div className={s.row}>
          <div>Language<span className={s.required}>*</span>:</div>
          <div>
            <Select
              isSearchable
              placeholder='Select Language...'
              value={languageSelect}
              onChange={value => setLanguageSelect(value)}
              options={languageOptions} />
          </div>
        </div>
        <div className={s.row}>
          <div>Date of Event<span className={s.required}>*</span>:</div>
          <div className={s.dateBoard}>
            <div className={s.datepicker}>
              <Datepicker
                disabled={isView}
                minDate={Date.now() - 8.64e+7}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(date) => {
                  formHandler('startDate', date);
                  if (Date.parse(endDate) < Date.parse(date)) {
                    formHandler('endDate', date);
                  }
                }} />
            </div>
            <div>
              Time of Event<span className={s.required}>*</span>:
            </div>
            <div className={s.datepicker}>
              <Timepicker
                onChange={(time) => {
                  formHandler('startTime', time);
                }}
                value={startTime} />
            </div>
          </div>
        </div>
        <div className={s.row}>
          <div>Time Zone<span className={s.required}>*</span>:</div>
          <div>
            <TimeZoneDropDown
              toggleTimeZone={timeZone => timeZone && formHandler('timeZone', timeZone)}
              timeZone={formData.timeZone}
              isView={isView} />
          </div>
        </div>
        <div className={s.row}>
          <div>Region<span className={s.required}>*</span>:</div>
          <div>
            <Select
              isDisabled={isView}
              isSearchable
              placeholder='Select Region...'
              value={regionSelect}
              onChange={value => setRegionSelect(value)}
              options={regionOptions} />
          </div>
        </div>
        <div className={s.row}>
          <div>Country<span className={s.required}>*</span>:</div>
          <div>
            <Select
              isDisabled={isView}
              isSearchable
              placeholder='Select Country...'
              value={countrySelect}
              onChange={value => setcountrySelect(value)}
              options={countryOptions} />
          </div>
        </div>
        <div className={s.row}>
          <div>City:</div>
          <div>
            <Input
              name='city'
              onChange={({ target }) => !isView && formHandler(target.name, target.value)}
              value={formData.city} />
          </div>
        </div>
        <div className={s.row}>
          <div>Type of Meeting<span className={s.required}>*</span>:</div>
          <div className={s.radioBoard}>
            {eventTypes.map(item => (
              <div
                key={item}
                onClick={() => onTypeClick(item)}
                className={s.radioItem}>
                <div>
                  <Radio size='30px' active={item === formData.eventType} />
                </div>
                <div className={s.radioLable}>{EVENT_TYPE_NAMES[item]}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={s.row}>
          <div>Expected No. of Participants<span className={s.required}>*</span>:</div>
          <div>
            <EventExpectedParticipants
              value={formData.expectedParticipants}
              toggleValue={value => !isView && formHandler('expectedParticipants', value)} />
          </div>
        </div>
        <div className={s.row}>
          <div>Approved Patient Case<span className={s.required}>*</span>:</div>
          <div>
            {formData.cases.length
              ? formData.cases.map((item) => {
                const { baseInfo } = item;
                return (
                  <div key={item.id} className={s.patientRowWrapper}>
                    <Row className={s.patientRow}>
                      <div>
                        {`${baseInfo[GENDER]} ${baseInfo[FIRST_NAME]}${baseInfo[LAST_NAME]
                          ? ` ${baseInfo[LAST_NAME]}` : ''}, ${baseInfo[AGE]} - ${baseInfo[VISIT]} Visit`}
                      </div>
                    </Row>
                    {!isView
                  && <div onClick={() => removePatientCase(item.id)} className={s.recycleIcon} />}
                  </div>

                );
              })
              : <div>
                {!isView
                && <Button
                  disabled={isAddPatientDisabled}
                  onClick={() => setIsPatientModalActive(true)}
                  className={s.patientButton}>
                  + Add Patient Case
                </Button>}
              </div>
            }

          </div>
        </div>
        {!!formData.cases.length && <div className={s.row}>
          <div />
          <div>
            {!isView
            && <Button
              disabled={isAddPatientDisabled}
              onClick={() => setIsPatientModalActive(true)}
              className={s.patientButton}>
              + Add Patient Case
            </Button>}
          </div>
        </div>}
      </div>
      {/* MODAL */}
      {isPatientModalActive
      && <AddPatientModal
        patientCases={formData.cases}
        onOkClick={onPatientModalOk}
        fetchCases={getPatientsByLanguage}
        closeModal={() => setIsPatientModalActive(false)} />}
    </Modal>
  );
};

export default FutureEventModal;
