import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ROLES_IDS } from '../../../../constants/ids';
import { EVENT_KEYS } from '../../../../constants/names';
import {
  changePrevFilters, resetFilters, setPrevFilters, toggleFilterModal,
} from '../../../../redux/slices/filteredEventsSlice';
import { selectRole } from '../../../../redux/slices/userInfoSlice';
import { getFilterAreasThunk, getFilteredEventsThunk } from '../../../../redux/thunks/filteredEventsThunk';
import { Button } from '../../../UI';
import s from './FiltersModalActions.module.scss';

const { EVENT_MODERATOR } = USER_ROLES_IDS;
const { DATE_FROM, DATE_TILL } = EVENT_KEYS;

const FiltersModalActions = () => {
  const dispatch = useDispatch();
  const isEventsLoading = useSelector(state => state.filteredEvents.eventsData.isEventsLoading);
  const isLocationsLoading = useSelector(state => state.filteredEvents.isLocationsLoading);
  const startDate = useSelector(state => state.filteredEvents.filters[DATE_FROM]);
  const endDate = useSelector(state => state.filteredEvents.filters[DATE_TILL]);
  const isDateActive = useSelector(state => state.filteredEvents.filters.isDateActive);
  const eventsIds = useSelector(state => state.filteredEvents.eventsData.eventsIds);
  const { allCities, allCountries } = useSelector(state => state.filteredEvents.formatedLocations);

  const role = useSelector(selectRole);

  const closeModal = () => {
    dispatch(toggleFilterModal(false));
  };

  const onFilterClick = () => {
    dispatch(getFilteredEventsThunk());
    dispatch(changePrevFilters());
  };

  const isModerator = role === EVENT_MODERATOR;

  const onCancelClick = () => {
    eventsIds.length && dispatch(setPrevFilters());
    closeModal();
  };

  const onReset = () => {
    dispatch(resetFilters());
    dispatch(getFilterAreasThunk());
  };

  const isFilterDisable = useMemo(() => (!allCountries.checkCount || (allCities.visibleCount && !allCities.checkCount) || isLocationsLoading
    || (isDateActive && (!startDate || !endDate))), [ allCountries, allCities, isLocationsLoading, isDateActive, startDate, endDate ]);

  return (
    <div className={s.actions}>
      <div>
        {!isModerator && <Button
          onClick={onReset}
          variant='danger'>Reset</Button>}
      </div>
      <div>
        <Button variant='light' onClick={onCancelClick}>Cancel</Button>
        <Button
          loading={isEventsLoading}
          disabled={isFilterDisable}
          className={s.filterButton}
          onClick={onFilterClick}>Filter</Button>
      </div>
    </div>
  );
};

export default FiltersModalActions;
