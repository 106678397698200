/* eslint-disable consistent-return */
import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Can from '../../../../components/Can/Can';
import PatientsModal from '../../../../components/PatientsModal/PatientsModal';
import { Button } from '../../../../components/UI';
import Modal from '../../../../components/UI/Modal/Modal';
import { EVENT_CONFIGS_KEYS } from '../../../../configs/events.configs';
import { USER_ROLES_IDS } from '../../../../constants/ids';
import { VALID_IMAGE_TYPES, VALID_VIDEO_TYPES } from '../../../../constants/names';
import { PATIENT_CASES_MODAL_TABS_NAMES } from '../../../../constants/patient.constants';
import { modifySelectOptions } from '../../../../helpers/utils';
import { setAlert } from '../../../../redux/slices/alertSlice';
import { setConfirm } from '../../../../redux/slices/confirmSlice';
import { createModifyPatientThunk, deletePatientThunk, importPatientThunk } from '../../../../redux/thunks/patientsDataThunk';
import { getPatientsForModalThunk } from '../../../../redux/thunks/patientsModalThunk';
import PatientImagesSection from './components/PatientImagesSection';
import PatientInfoSection from './components/PatientInfoSection';
import PatientVideosSection from './components/PatientVideosSection';
import s from './PatientCasesModal.module.scss';

const { AREA, LANGUAGE } = EVENT_CONFIGS_KEYS;

const { SUPER_USER } = USER_ROLES_IDS;

const modalTabs = Object.values(PATIENT_CASES_MODAL_TABS_NAMES);

const initialState = {
  therapeuticArea: '',
  activeTranslation: {
    language: '',
    sheet: '',
  },
  avatar: null,
  files: [],
  videos: [],
  previous: null,
};

const PatientCasesModal = ({
  closeModal, type, patient, restartFetch, isView, setShowPhoto, setShowVideo,
}) => {
  const dispatch = useDispatch();
  const { isCreateLoading, isDeleteLoading } = useSelector(state => state.patientsData);
  const eventConfigs = useSelector(state => state.userInfo.eventConfigs.configs);

  const isModify = type === 'Modify';

  const [ formData, setFormData ] = useState(patient || initialState);
  const [ activeTab, setActiveTab ] = useState(PATIENT_CASES_MODAL_TABS_NAMES.INFO);
  const [ imgFile, setImgFile ] = useState(null);
  const [ imgUrl, setImgUrl ] = useState(null);
  const [ addFiles, setAddFiles ] = useState([]);
  const [ addVideoFiles, setAddVideoFiles ] = useState([]);
  const [ importedCases, setImportedCases ] = useState({});
  const [ isPatientModalActive, setIsPatientModalActive ] = useState(false);

  const therapeuticOptions = useMemo(() => (
    modifySelectOptions(eventConfigs[AREA])
  ), [ eventConfigs[AREA] ]);
  const [ therapeuticSelect, setTherapeuticSelect ] = useState(
    therapeuticOptions.find(item => item.value === formData.therapeuticArea) || null,
  );

  const languageOptions = useMemo(() => (
    modifySelectOptions(
      isModify
        ? formData.translations.map(item => item.language)
        : eventConfigs[LANGUAGE],
    )
  ), [ eventConfigs[LANGUAGE] ]);

  const [ languageSelect, setLanguageSelect ] = useState(() => (
    isModify
      ? languageOptions.find(item => item.value === formData.activeTranslation.language)
      : (languageOptions.find(item => item.value === 'English') || null)
  ));

  const activeFiles = [ ...(formData.files || []), ...addFiles ];
  const activeVideoFiles = [ ...(formData.videos || []), ...addVideoFiles ];

  const formHandler = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const fileHandler = async (e) => {
    if (!e.target.files.length) return;
    const file = e.target.files[0];
    if (!VALID_IMAGE_TYPES.includes(file.type)) {
      return dispatch(setAlert({ message: 'Invalid image type' }));
    }
    setImgUrl(URL.createObjectURL(file));
    setImgFile(file);
  };

  const toggleLanguageSelect = (language) => {
    if (isModify) {
      setFormData((prev) => {
        const state = { ...prev, translations: [ ...prev.translations ]};
        const prevTranslation = state.activeTranslation;
        const prevTranslationId = state.translations.findIndex(item => item.id === prevTranslation.id);
        if (prevTranslationId !== -1) {
          state.translations[prevTranslationId] = prevTranslation;
        }
        const nextLanguage = state.translations.find(item => item.language === language.value);
        if (nextLanguage) {
          state.activeTranslation = nextLanguage;
          setLanguageSelect(language);
        }

        return state;
      });
    } else {
      setLanguageSelect(language);
    }
  };

  const onFormSubmit = () => {
    if (isView) {
      return closeModal();
    }
    const formatedData = {
      files: formData.files,
      avatar: formData.avatar,
      videos: formData.videos,
      sheet: formData.activeTranslation.sheet,
      language: languageSelect?.value,
      therapeuticArea: therapeuticSelect?.value,
      importedCases: Object.values(importedCases),
      previous: formData.previous ? formData.previous.id : -1,
    };
    if (isModify) {
      formatedData.id = formData.id;
    }
    if (formData.previous) {
      formatedData.previous = formData.previous.id;
    }
    dispatch(createModifyPatientThunk({
      isModify, imgFile, closeModal, restartFetch, addFiles, addVideoFiles, ...formatedData,
    }));
  };

  const deletePatient = () => {
    dispatch(deletePatientThunk({ id: formData.id, restartFetch, closeModal }));
  };

  const onDeleteClick = () => {
    dispatch(setConfirm(deletePatient));
  };

  const filesHandler = async (e) => {
    if (!e.target.files.length) return;
    const { files } = e.target;
    let isAllValides = true;
    for (let i = 0; i < files.length; i += 1) {
      if (!VALID_IMAGE_TYPES.includes(files[i].type)) {
        isAllValides = false;
        break;
      }
      if (formData.files.filter((item => item.name.toLowerCase() === files[i].name.toLowerCase())).length
      || addFiles.filter((item => item.name.toLowerCase() === files[i].name.toLowerCase())).length) {
        return dispatch(setAlert({ message: `Image with name ${files[i].name} already exists` }));
      }
    }
    if (!isAllValides) {
      return dispatch(setAlert({ message: 'Invalid image type' }));
    }

    Object.values(files).forEach((file) => {
      file.new = true;
      file.mozJpeg = URL.createObjectURL(file);
    });

    setAddFiles(prev => [ ...prev, ...Object.values(e.target.files) ]);
  };

  const videoFilesHandler = async (e) => {
    if (!e.target.files.length) return;
    const { files } = e.target;
    let isAllValides = true;
    for (let i = 0; i < files.length; i += 1) {
      if (!VALID_VIDEO_TYPES.includes(files[i].type)) {
        isAllValides = false;
        break;
      }
      if (formData.videos.filter((item => item.name.toLowerCase() === files[i].name.toLowerCase())).length
      || addVideoFiles.filter((item => item.name.toLowerCase() === files[i].name.toLowerCase())).length) {
        return dispatch(setAlert({ message: `Video with name ${files[i].name} already exists` }));
      }
    }
    if (!isAllValides) {
      return dispatch(setAlert({ message: 'Invalid video type' }));
    }

    Object.values(files).forEach((file) => {
      file.new = true;
      file.url = URL.createObjectURL(file);
    });

    setAddVideoFiles(prev => [ ...prev, ...Object.values(e.target.files) ]);
  };

  const removeAvatar = () => {
    setImgUrl(null);
    formHandler('avatar', null);
  };

  const onRecycleClick = (name) => {
    const addImageIndex = addFiles.findIndex(image => image.name === name);
    if (addImageIndex !== -1) {
      return setAddFiles(addFiles.filter(item => item.name !== name));
    }
    if (!formData.files) return;
    return setFormData(prev => ({ ...prev, files: formData.files.filter(el => el.name !== name) }));
  };

  const onVideoRecycleClick = (name) => {
    const addVideoIndex = addVideoFiles.findIndex(video => video.name === name);
    if (addVideoIndex !== -1) {
      return setAddVideoFiles(addVideoFiles.filter(item => item.name !== name));
    }
    if (!formData.videos) return;
    return setFormData(prev => ({ ...prev, videos: formData.videos.filter(el => el.name !== name) }));
  };

  const onSheetChange = (e) => {
    const { value } = e.target;
    setFormData(prev => ({ ...prev, activeTranslation: { ...prev.activeTranslation, sheet: value }}));
  };

  const onImportClick = () => {
    const language = languageSelect.value;
    const sheetURL = formData.activeTranslation.sheet;
    dispatch(importPatientThunk({
      language,
      sheetURL,
      id: formData.id || -1,
    }))
      .unwrap()
      .then((res) => {
        setFormData(prev => (
          { ...prev, activeTranslation: { ...prev.activeTranslation, baseInfo: res }}
        ));
        setImportedCases(prev => ({ ...prev, [language]: { language, sheetURL }}));
      });
  };

  const onClearClick = () => {
    setFormData(prev => ({
      ...prev,
      activeTranslation: {
        ...prev.activeTranslation,
        sheet: '',
      },
    }));
  };

  const onPatientModalOk = (checkedCases) => {
    formHandler('previous', checkedCases[0]);
    setIsPatientModalActive(false);
  };

  const getPatients = page => dispatch(getPatientsForModalThunk({ page }));

  const isOkDisable = !isView && (!therapeuticSelect || !languageSelect || !formData.activeTranslation.sheet
  || isDeleteLoading || !formData.activeTranslation.baseInfo);

  const activeTabSwitch = () => {
    switch (activeTab) {
      case PATIENT_CASES_MODAL_TABS_NAMES.INFO:
        return (
          <PatientInfoSection
            isView={isView}
            isModify={isModify}
            formData={formData}
            therapeuticSelect={therapeuticSelect}
            setTherapeuticSelect={setTherapeuticSelect}
            languageSelect={languageSelect}
            setLanguageSelect={setLanguageSelect}
            therapeuticOptions={therapeuticOptions}
            languageOptions={languageOptions}
            formHandler={formHandler}
            fileHandler={fileHandler}
            imgUrl={imgUrl}
            onSheetChange={onSheetChange}
            onImportClick={onImportClick}
            onClearClick={onClearClick}
            toggleLanguageSelect={toggleLanguageSelect}
            setIsPatientModalActive={setIsPatientModalActive}
            removeAvatar={removeAvatar} />
        );
      case PATIENT_CASES_MODAL_TABS_NAMES.IMAGES:
        return (
          <PatientImagesSection
            isView={isView}
            activeFiles={activeFiles}
            filesHandler={filesHandler}
            onRecycleClick={onRecycleClick}
            setShowPhoto={setShowPhoto} />
        );
      case PATIENT_CASES_MODAL_TABS_NAMES.VIDEOS:
        return (
          <PatientVideosSection
            isView={isView}
            activeVideoFiles={activeVideoFiles}
            filesHandler={videoFilesHandler}
            onRecycleClick={onVideoRecycleClick}
            setShowVideo={setShowVideo} />
        );
      default:
        return (
          <PatientInfoSection
            isView={isView}
            isModify={isModify}
            formData={formData}
            therapeuticSelect={therapeuticSelect}
            setTherapeuticSelect={setTherapeuticSelect}
            languageSelect={languageSelect}
            setLanguageSelect={setLanguageSelect}
            therapeuticOptions={therapeuticOptions}
            languageOptions={languageOptions}
            formHandler={formHandler}
            fileHandler={fileHandler}
            imgUrl={imgUrl}
            onSheetChange={onSheetChange}
            onImportClick={onImportClick}
            onClearClick={onClearClick}
            toggleLanguageSelect={toggleLanguageSelect}
            setIsPatientModalActive={setIsPatientModalActive}
            removeAvatar={removeAvatar} />
        );
    }
  };

  return (
    <>
      <Modal
        title={`${isView ? 'View' : type} Patient Case`}
        actions={
          <div className={s.actions}>
            <div>
              <Can roles={[ SUPER_USER ]}>
                {isModify
                  && <Button
                    loading={isDeleteLoading}
                    disabled={isCreateLoading}
                    onClick={onDeleteClick}
                    variant='danger'>Delete</Button>}
              </Can>
            </div>
            <div>
              {!isView && <Button
                className={s.cancelButton}
                onClick={closeModal}
                variant={'light'}>
                Cancel
              </Button>}
              <Button
                loading={isCreateLoading}
                disabled={isOkDisable}
                onClick={onFormSubmit}>OK</Button>
            </div>
          </div>
        }>
        <div className={classNames(s.content)}>
          <div className={classNames(s.section, s.navbar)}>
            {modalTabs.map(tab => (
              <div
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={classNames(s.navItem, { [s.navItem_active]: activeTab === tab })}>
                {tab}
              </div>
            ))}
          </div>
          {activeTabSwitch(activeTab)}
        </div>
      </Modal>
      {/* MODAL */}
      {isPatientModalActive
        && <PatientsModal
          radio
          patientCases={formData.previous ? [ formData.previous ] : []}
          onOkClick={onPatientModalOk}
          fetchCases={getPatients}
          ignorCases={isModify ? [ formData.id ] : []}
          closeModal={() => setIsPatientModalActive(false)} />}
    </>
  );
};

export default PatientCasesModal;
